var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[(_vm.restartTask)?_c('div',{staticClass:"counter"},[_c('div',{staticClass:"counter__container text-center"},[_c('audio',{ref:"startFoscus",attrs:{"src":require("../assets/audio/start.mp3")}}),_c('audio',{ref:"endFocus",attrs:{"src":require("../assets/audio/stop.wav")}}),_c('h1',{staticClass:"text-h4 white--text text-center"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.task))+" ")]),_c('div',{staticClass:"counter__steps"},[_c('v-card',{staticClass:"d-flex mx-auto justify-center rounded-pill pt-1 pb-1",attrs:{"color":"rgb(229 57 53 / 16%)","max-width":"150","outlined":"","dark":""}},[_c('v-icon',{staticClass:"mr-1 ml-1 step-icon"},[_vm._v(" "+_vm._s(_vm.counterSteps === 0 ? 'mdi-sync-circle' : '')+" "+_vm._s(_vm.counterSteps >= 1 ? 'mdi-check-circle' : 'mdi-check-circle-outline')+" ")]),_c('v-icon',{staticClass:"mr-1 ml-1 step-icon"},[_vm._v(" "+_vm._s(_vm.counterSteps === 2 ? 'mdi-sync-circle' : '')+" "+_vm._s(_vm.counterSteps >= 3 ? 'mdi-check-circle' : 'mdi-check-circle-outline')+" ")]),_c('v-icon',{staticClass:"mr-1 ml-1 step-icon"},[_vm._v(" "+_vm._s(_vm.counterSteps === 4 ? 'mdi-sync-circle' : '')+" "+_vm._s(_vm.counterSteps >= 5 ? 'mdi-check-circle' : 'mdi-check-circle-outline')+" ")]),_c('v-icon',{staticClass:"mr-1 ml-1 step-icon"},[_vm._v(" "+_vm._s(_vm.counterSteps === 6 ? 'mdi-sync-circle' : '')+" "+_vm._s(_vm.counterSteps >= 7 ? 'mdi-check-circle' : 'mdi-check-circle-outline')+" ")])],1),_c('div',{staticClass:"timer-body text-center"},[_c('div',{staticClass:"base-timer",style:({
                width: _vm.height + 'px', 
                height: _vm.height + 'px'
              })},[_c('div',{staticClass:"circle",style:({
                width: ((_vm.height + 20) + "px"), 
                height: ((_vm.height + 20) + "px")
              })}),_c('svg',{staticClass:"base-timer__svg",attrs:{"viewBox":"0 0 100 100","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{staticClass:"base-timer__circle"},[_c('circle',{staticClass:"base-timer__path-elapsed",attrs:{"cx":"50","cy":"50","r":"45"}}),_c('path',{staticClass:"base-timer__path-remaining red",attrs:{"stroke-dasharray":_vm.circleDasharray,"d":"\n                      M 50, 50\n                      m -45, 0\n                      a 45,45 0 1,0 90,0\n                      a 45,45 0 1,0 -90,0\n                    "}})])]),_c('span',{staticClass:"base-timer__label",style:({
                  width: _vm.height + 'px', 
                  height: _vm.height + 'px'
                })},[_vm._v(" "+_vm._s(_vm.formattedTimeLeft)+" "),_c('p',{staticClass:"base-timer__sublabel"},[_vm._v(_vm._s(_vm.flow))])])])]),_c('div',{staticClass:"counter__buttons"},[(!_vm.play)?_c('v-btn',{staticClass:"mx-2",attrs:{"to":"/","fab":"","dark":"","small":"","depressed":""}},[_vm._v(" End ")]):_vm._e(),(_vm.play)?_c('v-btn',{staticClass:"mx-2 mainButton",attrs:{"fab":"","dark":"","x-large":"","depressed":""},on:{"click":_vm.start}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-play")])],1):_vm._e(),(!_vm.play)?_c('v-btn',{staticClass:"mx-2 mainButton",attrs:{"fab":"","dark":"","x-large":"","depressed":""},on:{"click":_vm.pause}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pause ")])],1):_vm._e(),(!_vm.play)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","depressed":""},on:{"click":_vm.skip}},[_vm._v("Skip")]):_vm._e()],1)],1)])]):_c('div',{staticClass:"finish-task"},[_c('div',{staticClass:"finish-task__container text-center"},[_c('h2',{staticClass:"text-h4"},[_vm._v("Congratulations!")]),_c('h3',{staticClass:"white--text text-h5 mb-10"},[_vm._v("The task has been completed!")]),_c('img',{attrs:{"src":require("../assets/img/restart.png")}}),_c('div',{staticClass:"finish-task__btn mt-13"},[_c('v-btn',{staticClass:"mainButton rounded-pill",on:{"click":_vm.startAgain}},[_vm._v(" Start Again ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }