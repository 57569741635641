<template>
  <v-row>
    <v-col>
      <div v-if="restartTask" class="counter">
        <div class="counter__container text-center">
          <audio ref="startFoscus" src="../assets/audio/start.mp3"></audio>
          <audio ref="endFocus" src="../assets/audio/stop.wav"></audio>
          <h1 class="text-h4 white--text text-center">
            {{ task | capitalize }}
          </h1>
          <div class="counter__steps">
            <v-card
              class="d-flex mx-auto justify-center rounded-pill pt-1 pb-1"
              color="rgb(229 57 53 / 16%)"
              max-width="150"
              outlined
              dark
            >
              <v-icon class="mr-1 ml-1 step-icon">
                {{ counterSteps === 0 ? 'mdi-sync-circle' : ''}}
                {{ counterSteps >= 1 ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
              <v-icon class="mr-1 ml-1 step-icon">
                {{counterSteps === 2 ? 'mdi-sync-circle' : ''}}
                {{ counterSteps >= 3 ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
              <v-icon class="mr-1 ml-1 step-icon">
                {{counterSteps === 4 ? 'mdi-sync-circle' : ''}}
                {{ counterSteps >= 5 ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
              <v-icon class="mr-1 ml-1 step-icon">
                {{counterSteps === 6 ? 'mdi-sync-circle' : ''}}
                {{ counterSteps >= 7 ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}
              </v-icon>
            </v-card>
            <div class="timer-body text-center">
              <div 
                class="base-timer"
                :style="{
                  width: height + 'px', 
                  height: height + 'px'
                }"
              >
              <div 
                class="circle"
                :style="{
                  width: `${height + 20}px`, 
                  height: `${height + 20}px`
                }"
              ></div>
                <svg
                  class="base-timer__svg"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g class="base-timer__circle">
                    <circle
                      class="base-timer__path-elapsed"
                      cx="50"
                      cy="50"
                      r="45"
                    ></circle>
                    <path
                      :stroke-dasharray="circleDasharray"
                      class="base-timer__path-remaining red"
                      d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                      "
                    ></path>
                  </g>
                </svg>
                <span 
                  class="base-timer__label"
                  :style="{
                    width: height + 'px', 
                    height: height + 'px'
                  }"
                >
                  {{ formattedTimeLeft }}
                  <p class="base-timer__sublabel">{{flow}}</p>
                </span>
              </div>
            </div>
            <div class="counter__buttons">
              <v-btn
                v-if="!play"
                to="/" 
                class="mx-2" 
                fab 
                dark 
                small 
                depressed
              > 
                End 
              </v-btn>
              <v-btn
                v-if="play"
                @click="start"
                class="mx-2 mainButton"
                fab
                dark
                x-large
                depressed
              >
                <!-- <span class="text-2 font-weight-light">Start</span> -->
              <v-icon dark>mdi-play</v-icon>
              </v-btn>
              <v-btn
                v-if="!play"
                @click="pause"
                class="mx-2 mainButton"
                fab
                dark
                x-large
                depressed
              >
                <!-- <span class="text-2 font-weight-light">Pause</span> -->
                <v-icon dark> mdi-pause </v-icon>
              </v-btn>
              <v-btn
                v-if="!play"
                @click="skip"
                class="mx-2" 
                fab 
                dark 
                small 
                depressed
              >Skip</v-btn>
            </div>
          </div>
        </div>
      </div>
      
      <div v-else class="finish-task">
        <div class="finish-task__container text-center">
          <h2 class="text-h4">Congratulations!</h2>
          <h3 class="white--text text-h5 mb-10">The task has been completed!</h3>
          <img src="../assets/img/restart.png">
          <div class="finish-task__btn mt-13">
            <v-btn
              @click="startAgain"
              class="mainButton rounded-pill"
            >
              Start Again
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import isMobileMixin from "../mixins/isMobileMixin";
import onResize from '../mixins/onResize';

const FULL_DASH_ARRAY = 283;

export default {
  data() {
    return {
      task: this.$route.params.task,
      id: this.$route.query.id,
      timePassed: 0,
      counterSteps: 0,
      timerInterval: null,
      play: true,
      executed: true,
      restartTask: true,
      flow: 'Flow',
      height: window.innerHeight / 2.5,
      // focusTime: 0.1,
      // shortBreak: 0.1,
      // longBreak: 0.3,
      focusTime: localStorage.FocusSession,
      shortBreak: localStorage.ShortBreak,
      longBreak: localStorage.LongBreak,
      notification: JSON.parse(localStorage.Notification),
      alarmSound: JSON.parse(localStorage.AlarmSound),
      autoStart: JSON.parse(localStorage.AutoStart),
    };
  },
  mixins: [isMobileMixin, onResize],
  methods: {
    start() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
      this.play = false;
      if (this.executed) {
        this.alarmSound ? this.$refs.startFoscus.play() : ''
        this.executed = false
      }
    },
    pause() {
      clearInterval(this.timerInterval);
      this.play = true;
    },
    skip() {
      this.calcLeftSessionFlowTime()
      this.timePassed = 0
      this.counterSteps++
      this.timerSteps()
      this.pause()
    },
    startAgain() {
      this.restartTask = true
    },
    timerSteps() {
      if (this.counterSteps % 2 == 0) {
        this.isMobile() ? '' : this.notification ? this.pushNotifications() : ''
        this.alarmSound ? this.$refs.startFoscus.play() : ''
        this.flow = 'Flow'
        if (this.counterSteps === 8) {
          this.pause()
          this.counterSteps = 0
          this.restartTask = false
          this.timerInterval = null
        }
      } else {
        this.alarmSound ? this.$refs.endFocus.play() : ''
        this.isMobile() ? '' : this.notification ? this.pushNotifications() : ''
        this.flow = 'Break'
      }
    },
    notificationsMessage() {
      new Notification("", {
        body:  this.counterSteps % 2 == 0 ? (this.counterSteps === 8 ? "Congrats! You finished! 🥳" : "Start working! 💻") : "Take a break! ☕",
        icon: "https://www.pomodorus.net/logo.png"
      })
    },
    pushNotifications() {
      if(Notification.permission === 'granted') {
        this.notificationsMessage()
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if(permission === 'granted') {
            this.notificationsMessage()
          }
        })
      }
    },
    onResize() {
      this.height = window.innerHeight / 2.5
    },
    calcSessionFlowTime() {
      if( !localStorage[this.id] ){
          localStorage.setItem(this.id, this.time);
      } else {
        if(this.flow === 'Flow') {
          localStorage.setItem(this.id, +localStorage[this.id] + this.time );
        }
      }
    },
    calcLeftSessionFlowTime() {
      let timeCalc = this.time - this.timeLeft;
        if( !localStorage[this.id] ){
          localStorage.setItem(this.id, timeCalc);
        } else {
        if(this.flow === 'Flow') {
          localStorage.setItem(this.id, +localStorage[this.id] + timeCalc );
        }
      }
    }
  },
  destroyed() {
    this.calcLeftSessionFlowTime()
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.calcSessionFlowTime()
        this.timePassed = 0
        this.counterSteps++
        this.timerSteps()
        this.autoStart ? '' : this.pause()
      }
    },
  },
  computed: {
    time() {
      if (this.counterSteps % 2 == 0) {
        return this.focusTime * 60
      } else if (this.counterSteps < 7) {
        return this.shortBreak * 60
      } else {
        return this.longBreak * 60
      }
    },
    timeLeft() {
      return this.time - this.timePassed;
    },
    // circle
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.time;
      return rawTimeFraction - (1 / this.time) * (1 - rawTimeFraction);
    },
    // circle time format
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      let minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.$root.$on('focusTimeSlider', (val) => {
      this.focusTime = val
    }),
    this.$root.$on('shortBreakSlider', (val) => {
      this.shortBreak = val
    }),
    this.$root.$on('longBreakSlider', (val) => {
      this.longBreak = val
    }),
    this.$root.$on('switchNotification', (val) => {
      this.notification = val
    }),
    this.$root.$on('switchAlarmSound', (val) => {
      this.alarmSound = val
    }),
    this.$root.$on('switchAutoStart', (val) => {
      this.autoStart = val
    })
  }
};
</script>

<style scoped lang="scss">
.base-timer {
  position: relative;
  // width: 250px;
  // height: 250px;
  margin: 0 auto;

  &__svg {
    transform: scaleX(1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
  }

  &__path-remaining {
    stroke-width: 4px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.red {
      color: rgb(166, 22, 11);
    }
  }
  &__sublabel,
  &__label {
    position: absolute;
    // width: 250px;
    // height: 250px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: white;
    font-weight: 500;
  }
  &__sublabel {
    font-size: 16px;
    bottom: -100px;
  }
}

h1.text-h4 {
  margin-bottom: 10%;
}
.v-progress-circular {
  margin: 1rem;
}
.v-progress-circular__info {
  font-size: 50px;
}
.counter .mainButton {
  padding: 0px !important;
}
.step-icon {
  color: #FB8A82
}
.body-2 {
  line-height: 0.5;
}

.counter,
.finish-task {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    color: #F14336;
    font-weight: 600;
  }
  img {
    max-width: 200px;
  }
}

.circle {
  position: absolute;
  top: -10px;
  left: -10px;
  border: 21px solid rgba(175, 24, 13, 0.2);
  border-radius:50%;
  background: transparent;
  // width:270px;
  // height:270px;
}

.timer-body {
  padding-top: 15%;
  padding-bottom: 15%;
}

@media only screen and (max-width: 700px) {
  h1.text-h4 {
    font-size: 20px !important;
    line-height: 20px;
    margin-bottom: 5%;
  }
}

</style>
